import axios from '@/libs/axios'

class DataService {
  getAll(page) {
    return axios.get(`/prices`);
  }
 
  update(data) {
    return axios.put(`/prices?key=${data.key}&value=${data.value}`);
  }
}
export default new DataService();